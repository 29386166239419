var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Project Code:","label-for":"code"}},[_c('ValidationProvider',{ref:"code",attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Project Code"},model:{value:(_vm.project.code),callback:function ($$v) {_vm.$set(_vm.project, "code", $$v)},expression:"project.code"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Enterprises:","label-for":"code"}},[_c('ValidationProvider',{ref:"enterprise",attrs:{"name":"enterprise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.project.enterprise),expression:"project.enterprise"}],staticClass:"form-control",attrs:{"id":"enterprise"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.project, "enterprise", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","value":""}},[_vm._v("Choose enterprise...")]),_vm._l((_vm.allEnterprises),function(i,key){return _c('option',{key:key,domProps:{"value":i.id}},[_c('span',{staticClass:"w-20 h-20 d-inline-block",style:([{ backgroundColor:i.color }])}),_vm._v(" "),_c('span',[_vm._v(_vm._s(i.title))])])})],2),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"English Title:","label-for":"english_title"}},[_c('ValidationProvider',{ref:"en_title",attrs:{"name":"Een_title","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"English Title"},model:{value:(_vm.project.en_title),callback:function ($$v) {_vm.$set(_vm.project, "en_title", $$v)},expression:"project.en_title"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Arabic Title:","label-for":"arabic_title"}},[_c('ValidationProvider',{ref:"ar_title",attrs:{"name":"ar_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Arabic Title"},model:{value:(_vm.project.ar_title),callback:function ($$v) {_vm.$set(_vm.project, "ar_title", $$v)},expression:"project.ar_title"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":"exampleInputcolor","label":"project color"}},[_c('ValidationProvider',{ref:"color",attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"color","id":"exampleInputcolor"},model:{value:(_vm.project.color),callback:function ($$v) {_vm.$set(_vm.project, "color", $$v)},expression:"project.color"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":"Sort","label":"Sort:"}},[_c('ValidationProvider',{ref:"sort",attrs:{"name":"Sort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"number","id":"Sort","placeholder":"Ex:1","data-vv-validate-on":"change|custom"},model:{value:(_vm.project.sort),callback:function ($$v) {_vm.$set(_vm.project, "sort", $$v)},expression:"project.sort"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))]),_c('br')])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Description","label-for":"projectDesc"}},[_c('b-form-textarea',{attrs:{"id":"projectDesc","rows":"2"},model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}})],1)],1),_c('div',{staticClass:"d-flex  mt-4 align-items-center justify-content-between"},[_c('b-form-checkbox',{model:{value:(_vm.project.is_active),callback:function ($$v) {_vm.$set(_vm.project, "is_active", $$v)},expression:"project.is_active"}},[_vm._v("Is Active")]),(_vm.edit)?_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Edit Project")]):_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Add New Project")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }